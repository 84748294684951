export const form = {
	id: null,
	modelType: undefined,
	name: undefined,
	meterChargesType: undefined,
	billingType: undefined,
	calculateType: undefined,
	isTieredBilling: undefined,
	fileStandard: undefined,
	tieredBillingDTOList: [],
	calculateUnit: undefined,
	billingAccuracy: undefined,
	choiceType: undefined,
	isCollectLiquidatedDamages: undefined,
	paymentTerm: undefined,
	chargeRate: undefined,
	chargeMax: undefined,
	remarks: undefined,
};
export const rules = {
	modelType: [
		{
			required: true,
			message: "请选择类型",
			trigger: "change",
		},
	],
	name: [
		{
			required: true,
			message: "请输入费用名",
			trigger: "blur",
		},
	],
	meterChargesType: [
		{
			required: true,
			message: "请选择类型",
			trigger: "change",
		},
	],
	billingType: [
		{
			required: true,
			message: "请选择类型",
			trigger: "change",
		},
	],
	isTieredBilling: [
		{
			required: true,
			message: "请选择阶梯计费",
			trigger: "change",
		},
	],
	calculateUnit: [
		{
			required: true,
			message: "请输入单价",
			trigger: "blur",
		},
	],
	billingAccuracy: [
		{
			required: true,
			message: "请选择计费精度",
			trigger: "change",
		},
	],
	choiceType: [
		{
			required: true,
			message: "请选择取舍方式",
			trigger: "change",
		},
	],
	isCollectLiquidatedDamages: [
		{
			required: true,
			message: "请选择收取违约金",
			trigger: "change",
		},
	],
	paymentTerm: [
		{
			required: true,
			message: "请输入支付期限",
			trigger: "blur",
		},
	],
	chargeRate: [
		{
			required: true,
			message: "请输入收取比率",
			trigger: "blur",
		},
	],
	chargeMax: [
		{
			required: true,
			message: "请输入收取上限",
			trigger: "blur",
		},
	],
};
export const options = {
	modelType: [
		{
			id: 1,
			name: "房屋收费标准",
		},
		{
			id: 2,
			name: "仪表收费标准",
		},
		{
			id: 3,
			name: "车位收费标准",
		},
		{
			id: 4,
			name: "临时收费标准",
		},
	],
	meterChargesType: [
		{
			id: 1,
			name: "房间表收费标准",
		},
		{
			id: 2,
			name: "公摊表收费标准",
		},
	],
	billingType: [
		{
			id: 1,
			name: "单价x计量方式",
		},
		{
			id: 2,
			name: "固定金额",
		},
	],
	calculateType: [
		// 计量方式（1.建筑面积【房屋收费标准】，2.使用面积【房屋收费标准】，3.公摊面积【房屋收费标准】，
		// 5.仪表用量【仪表收费标准】，6.房屋数分摊（仪表用量 / 绑定房屋数）【仪表收费标准】，7.
		// 房屋建筑面积分摊（仪表用量 * 建筑面积占比）【仪表收费标准】，8.房屋在住人口分摊（
		// 仪表用量 * 在住人口占比））【仪表收费标准】
		{
			id: 1,
			name: "建筑面积",
		},
		{
			id: 2,
			name: "使用面积",
		},
		{
			id: 3,
			name: "公摊面积",
		},
		{
			id: 5,
			name: "仪表用量",
		},
		{
			id: 6,
			name: "房屋数分摊",
		},
		{
			id: 7,
			name: "房屋建筑面积分摊",
		},
		{
			id: 8,
			name: "房屋在住人口分摊",
		},
	],
	isTrue: [
		{
			id: 1,
			name: "是",
		},
		{
			id: 2,
			name: "否",
		},
	],
	fileStandard: [
		{
			id: 1,
			name: "房屋楼层数",
		},
		{
			id: 2,
			name: "房屋合同时长",
		},
		{
			id: 3,
			name: "仪表用量",
		},
	],
	billingAccuracy: [
		{
			id: 1,
			name: "元",
		},
		{
			id: 2,
			name: "角",
		},
		{
			id: 3,
			name: "分",
		},
	],
	choiceType: [
		{
			id: 1,
			name: "四舍五入",
		},
		{
			id: 2,
			name: "向上取整",
		},
		{
			id: 3,
			name: "向下取整",
		},
	],
};
