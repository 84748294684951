<template>
  <div>
    <a-drawer
      :title="title"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '80px' }"
      @close="close"
    >
      <div class="drawer-content">
        基本信息
        <a-divider></a-divider>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          layout="vertical"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item prop="modelType" label="模块类型">
                <a-select
                  v-model="form.modelType"
                  placeholder="请输入模块类型"
                  style="width: 60%"
                >
                  <a-select-option
                    v-for="item in options.modelType"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="name" label="费用名称">
                <a-input
                  v-model="form.name"
                  placeholder="请输入费用名称"
                  style="width: 80%"
                >
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="form.modelType === 2">
              <a-form-model-item
                prop="meterChargesType"
                label="仪表收费标准类型"
              >
                <a-select
                  v-model="form.meterChargesType"
                  placeholder="请输入仪表收费标准类型"
                  style="width: 60%"
                >
                  <a-select-option
                    v-for="item in options.meterChargesType"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="billingType" label="计费方式">
                <a-select
                  v-model="form.billingType"
                  placeholder="请输入计费方式"
                  style="width: 60%"
                >
                  <a-select-option
                    v-for="item in options.billingType"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="form.billingType === 1">
              <a-form-model-item prop="calculateType" label="计量方式">
                <a-select
                  v-model="form.calculateType"
                  placeholder="请输入计量方式"
                  style="width: 60%"
                >
                  <a-select-option
                    v-for="item in options.calculateType"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="isTieredBilling" label="是否阶梯计费">
                <a-select
                  v-model="form.isTieredBilling"
                  placeholder="请选择是否阶梯计费"
                  style="width: 60%"
                >
                  <a-select-option
                    v-for="item in options.isTrue"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="form.isTieredBilling === 1">
              <a-form-model-item  prop="fileStandard" label="定档标准">
                <a-select
                  v-model="form.fileStandard"
                  placeholder="请选择定档标准"
                  style="width: 60%"
                >
                  <a-select-option
                    v-for="item in options.fileStandard"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" v-if="form.isTieredBilling === 1">
              <div>
                <a-form-model ref="tieredform" :model="tieredform">
                  <a-form-model-item
                    v-for="(input, index) in tieredform.inputs"
                    :key="index"
                    :label="index === 0 ? '阶梯计费设置' : ''"
                  >
                    <span style="margin-right: 8px">{{ index + 1 }}档</span>
                    <a-input
                      v-model.number="input.chargesStall"
                      placeholder="请输入收费档位"
                      style="width: 40%; margin-right: 8px"
                    />
                    <a-input
                      v-model.number="input.chargesStallAmount"
                      placeholder="请输入档位费用"
                      style="width: 40%; margin-right: 8px"
                    />
                    <a-icon
                      v-if="tieredform.inputs.length > 1"
                      class="dynamic-delete-button"
                      type="minus-circle-o"
                      :disabled="tieredform.inputs.length === 1"
                      @click="removeInput(input)"
                    />
                  </a-form-model-item>
                  <a-form-model-item>
                    <span style="margin-right: 8px">末档</span>
                    <a-input
                      placeholder="+∞"
                      disabled
                      style="width: 40%; margin-right: 8px"
                    />
                    <a-input
                      v-model.number="tieredform.lastInput.chargesStallAmount"
                      placeholder="请输入末档费用"
                      style="width: 40%; margin-right: 8px"
                    />
                  </a-form-model-item>
                  <a-form-model-item>
                    <a-button
                      type="dashed"
                      style="width: 60%"
                      @click="addInput"
                    >
                      <a-icon type="plus" /> 新增阶梯价格
                    </a-button>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="calculateUnit" label="计量单价">
                <a-input
                  v-model="form.calculateUnit"
                  placeholder="请输入计量单价"
                  style="width: 80%"
                >
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="billingAccuracy" label="计费精度">
                <a-select
                  v-model="form.billingAccuracy"
                  placeholder="请选择计费精度"
                  style="width: 60%"
                >
                  <a-select-option
                    v-for="item in options.billingAccuracy"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="choiceType" label="取舍方式">
                <a-select
                  v-model="form.choiceType"
                  placeholder="请选择取舍方式"
                  style="width: 60%"
                >
                  <a-select-option
                    v-for="item in options.choiceType"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                prop="isCollectLiquidatedDamages"
                label="是否收取违约金"
              >
                <a-select
                  v-model="form.isCollectLiquidatedDamages"
                  placeholder="请选择"
                  style="width: 60%"
                >
                  <a-select-option
                    v-for="item in options.isTrue"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="form.isCollectLiquidatedDamages === 1">
              <a-form-model-item prop="paymentTerm" label="支付期限">
                <a-input
                  v-model="form.paymentTerm"
                  placeholder="请输入支付期限"
                  style="width: 80%"
                  suffix="天"
                >
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="form.isCollectLiquidatedDamages === 1">
              <a-form-model-item prop="chargeRate" label="收取比率">
                <a-input
                  v-model="form.chargeRate"
                  placeholder="请输入收取比率"
                  style="width: 80%"
                  suffix="%"
                >
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="form.isCollectLiquidatedDamages === 1">
              <a-form-model-item prop="chargeMax" label="收取上限">
                <a-input
                  v-model="form.chargeMax"
                  placeholder="请输入收取上限"
                  style="width: 80%"
                  suffix="%"
                >
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item prop="remarks" label="备注">
                <a-textarea
                  v-model="form.remarks"
                  placeholder="请输入备注"
                  style="width: 80%"
                >
                </a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="close">
          关闭
        </a-button>
        <a-button type="primary" @click="submit"> 提交 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { form, rules, options } from "./form";
import {
	chargesInsert,
	chargesUpdate,
	chargesInfo,
} from "@/api/payment/chargeStandardManage";
export default {
	props: {
		show: Boolean,
		id: Number,
	},
	data() {
		return {
			title: "新增费用",
			form,
			rules,
			options,
			tieredform: {
				inputs: [
					{ 
						chargesStall: undefined, 
						chargesStallAmount: undefined
					}
				],
				lastInput: { //末档
					chargesStall: -1,
					chargesStallAmount:undefined
				}
			},
		};
	},
	methods: {
		removeInput(item) {
			let index = this.tieredform.inputs.indexOf(item);
			if (index !== -1) {
				this.tieredform.inputs.splice(index, 1);
			}
		},
		addInput() {
			let index = this.tieredform.inputs.length;
			this.tieredform.inputs.push({
				chargesStall: undefined,
				chargesStallAmount: undefined
			});
		},
		submit() {
			// 阶梯价格拼接末档
			let tieredBillingDTOList = []
			this.tieredform.inputs.forEach((input,index) =>{
				let tieredBill = {
					chargesStall:input.chargesStall,
					chargesStallAmount:input.chargesStallAmount,
					sort:index + 1
				}
				tieredBillingDTOList.push(tieredBill)
			})
			let last = {
				chargesStall: -1,
				chargesStallAmount:this.tieredform.lastInput.chargesStallAmount,
				sort:this.tieredform.inputs.length + 1
			}
			tieredBillingDTOList.push(last)
			this.form.tieredBillingDTOList = tieredBillingDTOList
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					if (this.form.id === null) {
						let res = await chargesInsert(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					} else {
						let res = await chargesUpdate(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					}
				}
			});
		},
		success() {
			this.$emit("success");
			this.close();
		},
		close() {
			this.$refs.ruleForm.resetFields();
			this.form.id = null;
			this.tieredform = {
				inputs: [
					{ 
						chargesStall: undefined, 
						chargesStallAmount: undefined
					}
				],
				lastInput: { //末档
					chargesStall: -1,
					chargesStallAmount:undefined
				}
			};
			this.$emit("close");
		},
	},
	watch: {
		id: {
			handler(val) {
				if (val !== null) {
					chargesInfo({ chargesId: val }).then((res) => {
						this.form = res.data;
						if(res.data.isTieredBilling===1) {
							let tieredBill = res.data.tieredBillingVoList;
							// 末档
							this.tieredform.lastInput.chargesStallAmount = tieredBill[tieredBill.length-1].chargesStallAmount;
							tieredBill.pop();
							for(let key in tieredBill){
								this.tieredform.inputs[key] = tieredBill[key]
							}
						}
						// 阶梯价格反显
						this.title = "修改费用";
					});
				} else {
					this.title = "新增费用";
					this.form.id = null;
				}
			},
			immediate: true,
		},
	},
};
</script>

<style lang="less" scoped>

</style>
