<template>
  <div>
    <div class="cardTitle">收费标准管理</div>
    <a-tabs v-model="searchForm.modelType" @change="changeType">
      <a-tab-pane :key="1" tab="房屋收费标准"> </a-tab-pane>
      <a-tab-pane :key="2" tab="仪表收费标准"> </a-tab-pane>
      <a-tab-pane :key="3" tab="车位收费标准"> </a-tab-pane>
      <a-tab-pane :key="4" tab="临时收费标准"> </a-tab-pane>
    </a-tabs>
    <searchForm :formItem="formItem" @getSearch="search($event)"></searchForm>
    <a-button style="margin: 10px" class="add-btn" @click="drawer.show = true">添加费用</a-button>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      @change="handleTableChange"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: selectionChoosed,
      }"
      :row-key="
        (record, index) => {
          return record.id;
        }
      "
    >
      <span slot="action" slot-scope="text, row">
        <a-space>
          <a class="ant-dropdown-link" @click="edit(row.id)">修改</a>
          <a class="ant-dropdown-link" @click="del([row.id])">删除</a>
        </a-space>
      </span>
    </a-table>
    <div class="action">
      <a-dropdown :disabled="!hasSelected">
        <a-menu slot="overlay" @click="handleMenuClick">
          <a-menu-item key="del"> 批量删除 </a-menu-item>
        </a-menu>
        <a-button> 批量操作 <a-icon type="down" /> </a-button>
      </a-dropdown>
      <span style="margin-left: 8px">
        <template v-if="hasSelected">
          {{ `已选择 ${selectedRowKeys.length} 条` }}
        </template>
      </span>
    </div>
	<charge-form :show='drawer.show' :id='drawer.editId' @success='formSuccess' @close='formClose'></charge-form>
  </div>
</template>

<script>
import chargeForm from './depend/form.vue'
import { formItem, columns, pagination } from "./depend/config";
import { chargesList, chargesDel } from "@/api/payment/chargeStandardManage";
export default {
	components:{
		chargeForm
	},
	data() {
		return {
			tableData: [],
			searchForm: {
				name: undefined,
				modelType: 1,
			},
			drawer: {
				show: false,
				editId: null,
			},
			formItem,
			columns,
			pagination,
			// 选择的index
			selectedRowKeys: [],
		};
	},
	mounted() {
		this.getData()
	},
	methods: {
		changeType(data){
			this.getData()
		},
		async getData() {
			let res = await chargesList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				...this.searchForm,
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total;
			this.selectedRowKeys = [];
		},
		search(data){
			this.searchForm = data;
			this.getData()
		},
		edit(id) {
			this.drawer = {
				editId:id,
				show: true
			}
		},
		del(ids) {
			this.$confirm({
				title: "是否删除",
				icon: "delete",
				onOk: async () => {
					let res = await chargesDel({ chargesIds: ids });
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getData();
					} else {
						this.$message.error(res.msg);
					}
				},
			});
		},
		// 选择，获取id
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		// 批量操作
		handleMenuClick(data) {
			if (data.key === "del") {
				this.del(this.selectedRowKeys);
			}
		},
		// 跳转
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
		formSuccess(){
			this.getData();
		},
		formClose() {
			this.drawer = {
				show:false,
				editId:null,
			}
		},
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>
<style lang="less" scoped></style>
