export const formItem = [
	{
		type: 'input',
		label:'费用名称',
		prop:'name',
		placeholder:'请输入'
	},
]
export const columns = [
	{
		title: "费用名称",
		dataIndex: "name",
	},
	{
		title: "计费方式",
		dataIndex: "billingType",
		customRender:function(billingType){
			switch (billingType) {
			case 1:
				return '单价*数量'
			case 2:
				return '固定金额'
			default:
				break;
			}
		}
	},
	{
		title: "计量方式",
		dataIndex: "calculateType",
		customRender:function(calculateType){
			switch (calculateType) {
			case 1:
				return '建筑面积'
			case 2:
				return '使用面积'
			case 3:
				return '公摊面积'
			case 5:
				return '仪表用量'
			case 6:
				return '房屋数分摊'
			case 7:
				return '房屋面积分摊'
			case 8:
				return '房屋在住人口分摊'
			default:
				break;
			}
		}
	},
	{
		title: "单价",
		dataIndex: "calculateUnit",
	},
	{
		title: "备注",
		dataIndex: "remarks",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}